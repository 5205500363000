import { Link } from "gatsby"
import React from "react"
import { FaRegCalendar, FaRegClock, FaRegFolderOpen } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <SEO title="Home" />
      <div className="blog-posts">
        {posts
          .filter(post => post.node.frontmatter.path !== null)
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className="blog-post-preview" key={post.id}>
                <h1>
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </h1>
                <p className="blog-post-metadata">
                  <span style={{ marginRight: "1.25rem" }}>
                    <FaRegCalendar style={{ verticalAlign: "text-bottom" }} />
                    &nbsp;&nbsp;
                    {post.frontmatter.date}
                  </span>
                  <span style={{ marginRight: "1.25rem" }}>
                    <FaRegClock style={{ verticalAlign: "text-bottom" }} />
                    &nbsp;&nbsp;
                    {post.fields.readingTime.text}
                  </span>
                  {post.frontmatter.category != null && (
                    <span>
                      <FaRegFolderOpen
                        style={{ verticalAlign: "text-bottom" }}
                      />
                      &nbsp;&nbsp;
                      {post.frontmatter.category}
                    </span>
                  )}
                </p>
                <p className="blog-post-excerpt">{post.excerpt}</p>
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            category
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

export default IndexPage
